import { PayloadAction } from "@reduxjs/toolkit";
import { cargasActions } from "./reducer";
import { apiCall } from "./../../utils/apiCall";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";

function* cargasWorker() {
  try {
    console.log("Buscando cargas");
    const res: AxiosResponse<{ data: Record<string, any>[] }> = yield call(
      apiCall,
      {
        // url: `/api/n01/?json=${JSON.stringify({
        //   where: {
        //     N01_032_N: { eq: 1 },
        //   },
        //   pagination: {
        //     current: 1,
        //     pageSize: 1000,
        //   },
        // })}`,
        url: `/apiCargas/cargas`,
        method: "GET",
      }
    );
    console.log("cargasRetornadas", res.data);
    yield put(cargasActions.cargasSuccess(res.data.data));
  } catch (error) {
    yield put(
      cargasActions.cargasError(
        `Erro ao carregar cargas: ${JSON.stringify(error)}`
      )
    );
  }
}

function* cargaWorker({ payload }: PayloadAction<string>) {
  try {
    console.log("Buscando carga", payload);
    const res: AxiosResponse<{ data: Record<string, any> }> = yield call(
      apiCall,
      {
        url: `/apiCargas/carga/${payload}`,
        method: "GET",
      }
    );
    console.log("cargaRetornada", res.data);
    yield put(cargasActions.cargaSuccess(res.data.data));
  } catch (error) {
    yield put(
      cargasActions.cargaError(
        `Erro ao carregar carga: ${JSON.stringify(error)}`
      )
    );
  }
}

function* carguistaWorker() {
  try {
    console.log("Buscando carguistas");
    const queryA46 = {
      where: {
        A46_003_N: {
          eq: 1,
        },
      },
      pagination: {
        pageSize: 1000,
        current: 1,
      },
      order: ["A46_002_C"],
    };
    const res: AxiosResponse<{ data: Record<string, any>[] }> = yield call(
      apiCall,
      {
        url: `/api/a46/?json=${JSON.stringify(queryA46)}`,
        method: "GET",
      }
    );
    console.log("carguista retornado", res.data);
    yield put(cargasActions.carguistaSuccess(res.data.data));
  } catch (error) {
    yield put(
      cargasActions.carguistaError(
        `Erro ao carregar carguistas: ${JSON.stringify(error)}`
      )
    );
  }
}

function* concluirWorker({
  payload,
}: PayloadAction<{
  UKEY: string;
  operador: string;
  navigate: NavigateFunction;
}>) {
  // yield put(cargasActions.concluirError(`Erro ao concluir carga: error`));

  try {
    console.log("concluindo carga");
    yield call(apiCall, {
      url: `/apiCargas/finish/${payload.UKEY}`,
      method: "POST",
      data: {
        operador: payload.operador,
      },
    });
    yield put(cargasActions.concluirSuccess());
    payload.navigate("/");
  } catch (error) {
    yield put(
      cargasActions.concluirError(
        `Erro ao concluir carga: ${JSON.stringify(error)}`
      )
    );
  }
}

function* checkPalletWorker({
  payload,
}: PayloadAction<{
  codigoPallet: string;
  setQtd: (qtd: number) => void;
  itens: Record<string, any>;
}>) {
  try {
    const res: { data: Record<string, any> } = yield call(apiCall, {
      url: `/apiCargas/pallet/${payload.codigoPallet}`,
      method: "GET",
    });

    yield put(cargasActions.checkPalletSuccess(res.data));
    if (res.data && res.data.j07) {
      payload.setQtd(1);
    }
    if (res.data && res.data.k15) {
      let quantidade = res.data.k15.K15_004_N - res.data.k15.K15_005_N;
      if (
        res.data.d04 &&
        payload.itens[res.data.d04.D04_001_C] &&
        quantidade >
          payload.itens[res.data.d04.D04_001_C].qtd -
            payload.itens[res.data.d04.D04_001_C].read
      ) {
        quantidade =
          payload.itens[res.data.d04.D04_001_C].qtd -
          payload.itens[res.data.d04.D04_001_C].read;
      }
      payload.setQtd(quantidade);
    }
  } catch (error: any) {
    if (error?.response?.data && typeof error?.response?.data === "object") {
      if (error?.response?.data?.error) {
        yield put(
          cargasActions.checkPalletError(
            `Error ao verificar pallet online: ${error?.response?.data?.error}`
          )
        );
        return;
      }
    }
    yield put(
      cargasActions.checkPalletError(
        `Error ao verificar pallet online: ${
          error?.response?.data ? error?.response?.data : JSON.stringify(error)
        }`
      )
    );
  }
}

function* addPalletWorker({
  payload,
}: PayloadAction<{
  ukey: string;
  codigoPallet: string;
  qtd: number;
  navigate: NavigateFunction;
}>) {
  try {
    yield call(apiCall, {
      url: `/apiCargas/pallet/${payload.ukey}`,
      method: "POST",
      data: {
        pallet: payload.codigoPallet,
        qtd: payload.qtd,
      },
    });
    yield put(cargasActions.addPalletSuccess());
    payload.navigate(`/carga/${payload.ukey}`);
  } catch (error: any) {
    console.log("error", error?.response);
    if (error?.response?.data) {
      yield put(
        cargasActions.addPalletError(
          `Erro ao incluir pallet: ${error?.response?.data}`
        )
      );
      return;
    }
    if (error?.response?.status === 423) {
      yield put(
        cargasActions.addPalletError(
          `O carregamento desta carga foi bloqueado!`
        )
      );
    } else {
      yield put(
        cargasActions.addPalletError(
          `Erro ao incluir pallet: ${JSON.stringify(error)}`
        )
      );
    }
  }
}

function* cancelPalletWorker({
  payload,
}: PayloadAction<{
  ukey: string;
  codigoPallet: string;
  navigate: NavigateFunction;
}>) {
  try {
    yield call(apiCall, {
      url: `/apiCargas/pallet/${payload.ukey}`,
      method: "DELETE",
      data: {
        pallet: payload.codigoPallet,
      },
    });
    yield put(cargasActions.cancelPalletSuccess());
    payload.navigate(`/carga/${payload.ukey}`);
  } catch (error: any) {
    console.log("error", error?.response);
    if (error?.response?.data) {
      yield put(
        cargasActions.cancelPalletError(
          `Erro ao cancelar leitura de pallet: ${error?.response?.data}`
        )
      );
      return;
    }
    if (error?.response?.status === 423) {
      yield put(
        cargasActions.addPalletError(
          `O carregamento desta carga foi bloqueado!`
        )
      );
    } else {
      yield put(
        cargasActions.addPalletError(
          `Erro ao ao cancelar leitura de pallet: ${JSON.stringify(error)}`
        )
      );
    }
  }
}

function* rippedWorker({
  payload,
}: PayloadAction<{ carga: string; item: string; qtd: number }>) {
  try {
    yield call(apiCall, {
      url: `/apiCargas/ripped/${payload.carga}/${payload.item}/${payload.qtd}`,
      method: "POST",
    });
    alert("Rasgado registrado com sucesso!");
    yield put(cargasActions.rippedSuccess());
    yield put(cargasActions.requestCarga(payload.carga));
  } catch (error: any) {
    if (error?.response?.data) {
      yield put(
        cargasActions.rippedError(
          `Erro ao registrar rasgado: ${error.response.data}`
        )
      );
    } else {
      yield put(
        cargasActions.rippedError(
          `Erro ao registrar rasgado: ${JSON.stringify(error)}`
        )
      );
    }
  }
}

function* consumeWorker({
  payload,
}: PayloadAction<{
  codigoPallet: string;
  motivo: string;
  qtd: number;
  navigate: NavigateFunction;
}>) {
  try {
    yield call(apiCall, {
      url: `/apiCargas/consume/${payload.codigoPallet}`,
      method: "POST",
      data: {
        qtd: payload.qtd,
        motive: payload.motivo,
        showPicking: payload.motivo === "Picking",
      },
    });
    yield put(cargasActions.consumeSuccess());
    payload.navigate("/");
  } catch (error: any) {
    yield put(
      cargasActions.consumeError(
        `Erro ao consumir pallet: ${
          error?.response?.data ? error?.response?.data : JSON.stringify(error)
        }`
      )
    );
  }
}

export default function* cargasSaga() {
  yield all([
    takeLatest("cargas/requestCargas", cargasWorker),
    takeLatest("cargas/requestCarga", cargaWorker),
    takeLatest("cargas/carguistaRequest", carguistaWorker),
    takeLatest("cargas/concluirRequest", concluirWorker),
    takeLatest("cargas/requestCheckPallet", checkPalletWorker),
    takeLatest("cargas/addPalletRequest", addPalletWorker),
    takeLatest("cargas/cancelPalletRequest", cancelPalletWorker),
    takeLatest("cargas/rippedRequest", rippedWorker),
    takeLatest("cargas/consumeRequest", consumeWorker),
  ]);
}
