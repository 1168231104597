import {
  Button,
  Center,
  CircularProgress,
  Flex,
  IconButton,
  Text,
} from "@chakra-ui/react";
import {
  faBoxes,
  faCog,
  faPaperPlane,
  faRefresh,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIsAuth } from "../../hooks/useIsAuth";
import { RootState } from "../app/mainReducer";
import { inventoryActions } from "../inventory/reducer";
import { loginActions } from "../login/reducer";
import { cargasActions } from "./reducer";
import { ShippingItem } from "./shippingItem";

export const CargasList: React.FC<{}> = () => {
  useIsAuth();

  const navigate = useNavigate();

  const usuario = useSelector((state: RootState) => state.login.usuario);

  const isLoading = useSelector((state: RootState) => state.cargas.isLoading);

  const cargas = useSelector((state: RootState) => state.cargas.cargas);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(cargasActions.requestCargas());
  }, [dispatch]);

  return (
    <Flex direction="column" w="100vw" h="100vh">
      <Flex direction="column" bgColor="blue.200">
        <Center
          justifyContent="space-between"
          alignSelf="center"
          px="1rem"
          py="0.5rem"
          maxW="300px"
        >
          <IconButton
            colorScheme="blue"
            aria-label="Configurações"
            mr="0.4rem"
            onClick={() => navigate(`/config`)}
            icon={
              <FontAwesomeIcon
                style={{ marginLeft: "1rem", marginRight: "1rem" }}
                icon={faCog}
                title="Configurações"
                color="#eee"
              />
            }
          />
          <IconButton
            colorScheme="blue"
            aria-label="Consumo"
            mr="0.4rem"
            onClick={() => navigate(`/consume`)}
            icon={
              <FontAwesomeIcon
                style={{ marginLeft: "1rem", marginRight: "1rem" }}
                icon={faPaperPlane}
                title="Consumo"
                color="#eee"
              />
            }
          />
          <IconButton
            colorScheme="blue"
            aria-label="Inventario"
            mr="0.4rem"
            onClick={() => {
              dispatch(inventoryActions.startInventory());
              navigate(`/inventory`);
            }}
            icon={
              <FontAwesomeIcon
                style={{ marginLeft: "1rem", marginRight: "1rem" }}
                icon={faBoxes}
                title="Inventario"
                color="#eee"
              />
            }
          />
          <IconButton
            colorScheme="blue"
            aria-label="Recarregar"
            mr="0.4rem"
            onClick={() => dispatch(cargasActions.requestCargas())}
            icon={
              <FontAwesomeIcon
                style={{ marginLeft: "1rem", marginRight: "1rem" }}
                icon={faRefresh}
                title="Recarregar"
                color="#eee"
              />
            }
          />
          <IconButton
            colorScheme="blue"
            aria-label="Sair"
            mr="0.4rem"
            onClick={() => dispatch(loginActions.logout())}
            icon={
              <FontAwesomeIcon
                style={{ marginLeft: "1rem", marginRight: "1rem" }}
                icon={faSignOut}
                title="Sair"
                color="#eee"
              />
            }
          />
        </Center>
        <Center my="0.8rem">
          <Text>Usuario: {usuario}</Text>
        </Center>
        <Center flexDirection="column" my="0.5rem">
          <Text size="2xl" as="b">
            Cargas SAERP
          </Text>
          <Button size="xs" borderRadius="full" colorScheme="blue">
            v1.0.9
          </Button>
        </Center>
      </Flex>
      <Flex direction="column" px="0.3rem" overflowY="scroll" py="0.3rem">
        {isLoading && <CircularProgress isIndeterminate={true} />}
        {cargas &&
          cargas.map((carga) => (
            <ShippingItem
              onClick={() => navigate(`/carga/${carga.UKEY}`)}
              carga={carga}
            />
          ))}
      </Flex>
    </Flex>
  );
};
