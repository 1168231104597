import React, { useRef } from "react";
import { useSelector } from "react-redux";
//import { useScanner } from "../hooks/useScanner";
import { RootState } from "../modules/app/mainReducer";
import Scanner from "./Scanner";

type QuaggaBarcodeReaderProps = {
  isOpened: boolean;
  onReadBarcode: (code: string) => void;
  onClose: () => void;
};

// export const QuaggaBarcodeReader: React.FC<QuaggaBarcodeReaderProps> = ({
//   isOpened,
//   onReadBarcode,
//   onClose,
// }) => {
//   const scannerRef = useRef(null);
//   const width = useSelector((state: RootState) => state.config.width || 1920);
//   const height = useSelector((state: RootState) => state.config.height || 1080);
//   const patchSize = useSelector(
//     (state: RootState) => state.config.patchSize || "small"
//   );

//   useScanner(
//     (code) => code && onReadBarcode(code),
//     scannerRef,
//     isOpened,
//     undefined,
//     undefined,
//     undefined,
//     { width, height },
//     {
//       patchSize,
//       halfSample: false,
//     },
//     0,
//     undefined,
//     true,
//     onClose
//   );

//   return (
//     <div
//       ref={scannerRef}
//       style={{ position: "relative", border: "3px solid red", display: "none" }}
//     >
//       <canvas
//         className="drawingBuffer"
//         style={{
//           position: "absolute",
//           top: "0px",
//           border: "3px solid green",
//         }}
//         width="640"
//         height="480"
//       />
//     </div>
//   );
// };

export const QuaggaBarcodeReader: React.FC<QuaggaBarcodeReaderProps> = ({
  isOpened,
  onReadBarcode,
  onClose,
}) => {
  const scannerRef = useRef(null);
  const width = useSelector((state: RootState) => state.config.width || 1920);
  const height = useSelector((state: RootState) => state.config.height || 1080);
  const patchSize = useSelector(
    (state: RootState) => state.config.patchSize || "small"
  );

  return (
    <div
      ref={scannerRef}
      style={{ position: "relative", border: "3px solid red" }}
    >
      <canvas
        className="drawingBuffer"
        style={{
          position: "absolute",
          top: "0px",
          border: "3px solid green",
        }}
        width="640"
        height="480"
      />
      {isOpened ? (
        <Scanner
          scannerRef={scannerRef}
          onDetected={(code) => onReadBarcode(code || "")}
          onClose={onClose}
          constraints={{
            width,
            height,
          }}
          locator={{
            patchSize,
            halfSample: false,
          }}
        />
      ) : null}
    </div>
  );
};
