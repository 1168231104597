import React, { RefObject, useCallback, useLayoutEffect } from "react";
import Quagga, {
  QuaggaJSCodeReader,
  QuaggaJSResultCallbackFunction,
  QuaggaJSResultObject,
} from "@ericblade/quagga2";

function getMedian(arr: any[]) {
  arr.sort((a, b) => a - b);
  const half = Math.floor(arr.length / 2);
  if (arr.length % 2 === 1) {
    return arr[half];
  }
  return (arr[half - 1] + arr[half]) / 2;
}

function getMedianOfCodeErrors(decodedCodes: any[]) {
  const errors = decodedCodes
    .filter((x) => x.error !== undefined)
    .map((x) => x.error);
  const medianOfErrors = getMedian(errors);
  return medianOfErrors;
}

const defaultConstraints = {
  width: 640,
  height: 480,
};

const defaultLocatorSettings = {
  patchSize: "medium",
  halfSample: true,
};

const defaultDecoders: QuaggaJSCodeReader[] = ["code_128_reader"];

const Scanner: React.FC<{
  onDetected: (code: string | null) => void;
  scannerRef: RefObject<any>;
  onScannerReady?: () => void;
  cameraId?: string;
  facingMode?: string;
  constraints?: Record<string, any>;
  locator?: Record<string, any>;
  numOfWorkers?: number;
  decoders?: QuaggaJSCodeReader[];
  locate?: boolean;
  onClose?: () => void;
}> = ({
  onDetected,
  scannerRef,
  onScannerReady,
  onClose,
  cameraId,
  facingMode,
  constraints = defaultConstraints,
  locator = defaultLocatorSettings,
  numOfWorkers = navigator.hardwareConcurrency || 0,
  decoders = defaultDecoders,
  locate = true,
}) => {
  const errorCheck = useCallback(
    (result: QuaggaJSResultObject) => {
      if (!onDetected) {
        return;
      }
      const err = getMedianOfCodeErrors(result.codeResult.decodedCodes);
      // if Quagga is at least 75% certain that it read correctly, then accept the code.
      if (err < 0.25) {
        onDetected(result.codeResult.code);
      }
    },
    [onDetected]
  );

  const handleProcessed: QuaggaJSResultCallbackFunction = (result) => {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;
    drawingCtx.font = "24px Arial";
    drawingCtx.fillStyle = "green";

    if (result) {
      // console.warn('* quagga onProcessed', result);
      if (result.boxes) {
        drawingCtx.clearRect(
          0,
          0,
          parseInt(drawingCanvas.getAttribute("width") || "0"),
          parseInt(drawingCanvas.getAttribute("height") || "0")
        );
        result.boxes
          .filter((box) => box !== result.box)
          .forEach((box) => {
            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
              color: "purple",
              lineWidth: 2,
            });
          });
      }
      if (result.box) {
        Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
          color: "blue",
          lineWidth: 2,
        });
      }
      if (result.codeResult && result.codeResult.code) {
        // const validated = barcodeValidator(result.codeResult.code);
        // const validated = validateBarcode(result.codeResult.code);
        // Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: validated ? 'green' : 'red', lineWidth: 3 });
        drawingCtx.font = "24px Arial";
        // drawingCtx.fillStyle = validated ? 'green' : 'red';
        // drawingCtx.fillText(`${result.codeResult.code} valid: ${validated}`, 10, 50);
        drawingCtx.fillText(result.codeResult.code, 10, 20);
        // if (validated) {
        //     onDetected(result);
        // }
      }
    }
  };

  useLayoutEffect(() => {
    console.log("init called", scannerRef);
    setTimeout(() => {
      console.log("init starting", scannerRef);
      Quagga.init(
        {
          inputStream: {
            type: "LiveStream",
            constraints: {
              ...constraints,
              ...(cameraId && { deviceId: cameraId }),
              ...(!cameraId && { facingMode }),
            },
            target: scannerRef?.current,
            area: {
              top: "30%",
              left: "5%",
              right: "5%",
              bottom: "30%",
            },
          },
          locator,
          frequency: 10,
          numOfWorkers,
          decoder: { readers: decoders },
          locate,
        },
        (err) => {
          console.log("init completed", err, scannerRef);
          Quagga.onProcessed(handleProcessed);

          if (err) {
            // alert("Erro ao abrir camera: " + JSON.stringify(err));
            console.log("Error starting Quagga:", err);
            //if (onClose) onClose();

            return;
          }
          if (scannerRef && scannerRef.current) {
            Quagga.start();
            if (onScannerReady) {
              onScannerReady();
            }
          }
        }
      );
      console.log("Registering ondetected");
      Quagga.onDetected(errorCheck);
      console.log("Registered ondetected");
    }, 100);
    return () => {
      console.log("Calling dismount");
      Quagga.offDetected(errorCheck);
      Quagga.offProcessed(handleProcessed);
      Quagga.stop();
    };
  }, [
    cameraId,
    onDetected,
    onScannerReady,
    scannerRef,
    facingMode,
    numOfWorkers,
    errorCheck,
    constraints,
    locator,
    decoders,
    locate,
    onClose,
  ]);
  return null;
};

export default Scanner;
